<template>
  <div id="news-news">
    <div class="news-banner">
      <div class="news-title">{{ $t('news-news.title') }}</div>
      <div class="news-slogan">{{ $t('news-news.slogan') }}</div>
    </div>
    <div class="news-content">
      <section class="new-list-section">
        <ul class="news-list">
          <li v-for="news in newsList" :key="news.ArticleId" class="news-item">
            <figure class="news-figure">
              <div class="news-figure-img-wrap">
                <img
                  v-if="news.ImgSrc"
                  class="news-figure-img"
                  :src="news.ImgSrc"
                  @click="showNews(news)"
                />
                <img
                  v-else
                  class="news-figure-img"
                  src="@/assets/news/news-default.jpg"
                  @click="showNews(news)"
                />
              </div>
              <div class="news-figure-info">
                <div class="news-figure-title">{{ news.Title }}</div>
                <div class="news-figure-time">{{ news.IssuedAtStr }}</div>
              </div>
            </figure>
          </li>
        </ul>
      </section>
      <section v-if="totalPages > 1" class="news-page-section">
        <ul class="news-page-list">
          <template v-if="totalPages <= 5">
            <li
              v-for="index in 5"
              v-if="index <= totalPages"
              :key="index"
              class="news-page-item"
              :class="{ active: index === currentPage }"
              @click="getNewsListByPage(index)"
            >
              {{ index }}
            </li>
          </template>
          <template v-else>
            <li v-if="startPage >= 2" class="news-page-item" @click="getNewsListByPage(1)">1</li>
            <li v-if="startPage === 3" class="news-page-item" @click="getNewsListByPage(2)">2</li>
            <li v-else-if="startPage > 3">...</li>
            <li
              v-for="index in 5"
              v-if="startPage + index - 1 <= totalPages"
              :key="index"
              class="news-page-item"
              :class="{ active: startPage + index - 1 === currentPage }"
              @click="getNewsListByPage(startPage + index - 1)"
            >
              {{ startPage + index - 1 }}
            </li>
            <li v-if="totalPages >= startPage + 5">...</li>
          </template>
        </ul>
      </section>
    </div>
    <div v-if="currentNews" class="news-current">
      <div class="news-current-header">
        <div class="news-current-title-wrap">
          <div class="news-current-title">{{ currentNews.Title }}</div>
          <div class="news-current-time">{{ currentNews.IssuedAtStr }}</div>
        </div>
        <div class="news-current-close" @click="hideNews()">X</div>
      </div>
      <div class="news-current-content" v-html="currentNews.content" />
    </div>
  </div>
</template>

<script>
  import { getSportNewsApi } from '@/api/sport';

  export default {
    data() {
      return {
        newsList: [],
        pageSize: 12,
        currentPage: 1,
        startPage: 1,
        totalCount: 0,
        totalPages: 1,
        currentNews: null,
      };
    },
    methods: {
      getNewsListByPage(page) {
        if (page === this.currentPage) return;
        const payload = { pageSize: this.pageSize, pageIndex: page };
        getSportNewsApi(payload).then((response) => {
          const { List, Currentpage, TotalCount, TotalPages } = response.data;
          this.newsList = List;
          this.currentPage = Currentpage;
          this.totalCount = TotalCount;
          this.totalPages = TotalPages;
          this.updateStartPage();

          const element = document.getElementById('news');
          if (element) {
            element.scrollTop = 0;
          }
        });
      },
      updateStartPage() {
        const { currentPage, totalPages } = this;
        let start = 1;
        if (totalPages < 5) {
          start = totalPages - 4;
          if (start < 1) {
            start = 1;
          }
        } else if (currentPage > 3) {
          start = currentPage - 2;
        }
        this.startPage = start;
      },
      showNews(news) {
        this.currentNews = news;
      },
      hideNews() {
        this.currentNews = null;
      },
    },
    beforeCreate() {
      const payload = { Pagesize: 12, Currentpage: 1 };
      getSportNewsApi(payload).then((response) => {
        const { List, Currentpage, TotalCount, TotalPages } = response.data;
        this.newsList = List;
        this.currentPage = Currentpage;
        this.totalCount = TotalCount;
        this.totalPages = TotalPages;
        this.updateStartPage();
      });
    },
  };
</script>

<style lang="scss">
  @import './news.scss';
</style>

<style lang="scss" scoped>
  #news-news {
    width: 100%;
  }

  ::v-deep .news-current-content img {
    width: 100% !important;
    height: auto !important;
  }
</style>
